import * as jQuery from "jquery";
import * as FilePond from "filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "filepond-plugin-pdf-preview/dist/filepond-plugin-pdf-preview.min.css";

// Register the plugin
FilePond.registerPlugin(FilePondPluginImagePreview);
FilePond.registerPlugin(FilePondPluginPdfPreview);

export default () => {
    (function($) {

        // lazy init on tab change
        $('.nav .nav-link').on('click', function() {
            init($(`${$(this).attr('href')}`));
        });

        // init active tab on load
        init($(`${$('.nav-link.active').attr('href')}`));

    })(jQuery);

    function init (panel: JQuery<HTMLElement>|null) {
        if(panel === null) {
            return null;
        }
        const element = <JQuery<HTMLElement>> panel.find('.upload');
        if(element.hasClass('filepond--root')) {
            return null;
        }
        const load = element.data('load') === 1;
        const pond = FilePond.create(element[0], {
            labelIdle: 'Ziehen Sie Ihre Dateien per Drag & Drop oder <span class="filepond--label-action"> durchsuchen </span>',
            labelFileProcessing: 'Datei wird hochgeladen',
            labelFileProcessingComplete: 'Datei wurde hochgeladen',
            labelFileProcessingAborted: 'Datei wurde nicht hochgeladen',
            labelFileProcessingError: 'Es ist ein Fehler beim hochladen der Datei aufgetreten',
            labelTapToUndo: 'Rückgängig machen',
            labelTapToCancel: 'Abbrechen',
            server: {
                process: `/api/documents/${element.data('id')}/${element.data('lang')}`,
                revert: `/api/documents/${element.data('id')}/${element.data('lang')}`,
                load: `/api/v2/documents/${element.data('lang')}/view?load=`,
            },
        });
        if(load) {
            pond.setOptions({
                files: [{
                    source: <string> element.data('id'),
                    options: {
                        type: "local",
                    }
                }]
            });
        }
    }

};