import * as jQuery from "jquery";
import { responseHandler } from "./utility";

export default () => {

    (function($) {

        $('#login').on('submit', function(event){
            const _this = $(this);
            event.preventDefault();
            $.ajax({
                type: 'post',
                url: '/login',
                data: _this.serialize(),
                dataType: 'json',
                statusCode: {
                    200: function(response){
                        const location = responseHandler(response, () => null, 1000);
                        setTimeout(function(){
                            window.location.replace(location);
                        }, 1000);
                    },
                    400: function(response){
                        responseHandler(response.responseJSON);
                    }
                }
            });
        });

    })(jQuery);

}