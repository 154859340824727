import * as $ from "jquery";
import { responseHandler, setInvalid, setValid } from "./utility";
import iziToast from "izitoast";

export default () => {

    $('#elementForm').on('submit', function (e) {
        e.preventDefault();
        let data = new FormData();
        data.append('user', <string>$('#elementUsername').val());
        data.append('element', <string>$('#elementElement').val());
        data.append('name', <string>$('#elementTitle').val());
        $.ajax({
            method: 'post',
            url: '/api/element',
            data: data,
            processData: false,
            contentType: false,
            dataType: 'json',
        }).then(response => {
            responseHandler(response, () => {
                if (response.status === 200) {
                    window.location.reload();
                }
            });
        });
    });

    $('.delete-element').on('click', function () {
        let row = $(this).parent().parent();
        let id = $(this).data('element');
        iziToast.question({
            timeout: 0,
            close: false,
            overlay: true,
            id: 'question',
            zindex: 999,
            color: 'white',
            message: 'Soll der Eintrag wirklich gelöscht werden?',
            position: 'topCenter',
            buttons: [
                ['<button>Ja</button>', function (instance, toast) {
                    $.ajax({
                        method: 'delete',
                        url: `/api/element/${id}`,
                        dataType: 'json'
                    }).then(response => {
                        responseHandler(response);
                        if (response.status === 200) {
                            row.hide('slow', function () {
                                $(`#${id}`).remove();
                            });
                        }
                    });
                    instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
                }, true],
                ['<button>Nein</button>', function (instance, toast) {
                    instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
                }, false]
            ]
        });
    });

    $('#elementDeleteSort').on('change', function () {
        const id = $(this).val();
        if (id != 'all') {
            $('.elementDeleteTableRow').each(function () {
                if ($(this).data('user') != id) {
                    $(this).fadeOut();
                } else {
                    $(this).fadeIn();
                }
            });
        } else {
            $('.elementDeleteTableRow').slideDown();
        }
    });

    $('#userChangePassword').on('submit', function (e) {
        setValid();
        e.preventDefault();
        const id = $(this).data('id');
        const data = new FormData();
        data.append('userOldPassword', <string>$('#userOldPassword').val());
        data.append('userNewPassword', <string>$('#userNewPassword').val());
        $.ajax({
            method: 'post',
            url: '/user/password/' + id,
            data: data,
            processData: false,
            contentType: false,
            dataType: 'json',
            statusCode: {
                200: function (response) {
                    responseHandler(response);
                },
                400: function (response) {
                    $.each(responseHandler(response.responseJSON), function (i, element) {
                        setInvalid('#' + element);
                    });
                },
                500: function (response) {
                    responseHandler(response.responseJSON);
                }
            }
        })
    });

    $('.input-group-btn button').on('click', function (e) {
        e.preventDefault();
        $(this).find('i').toggleClass('fa-eye fa-eye-slash');
        const password = $(this).parent().parent().find('input');
        if (password.attr('type') === 'password') {
            password.attr('type', 'text');

        } else {
            password.attr('type', 'password');
        }
    });

    $('#resetPassword').on('click', function (e) {
        e.preventDefault();
        const pw = $('#userResetPassword').find(':selected');
        iziToast.question({
            timeout: 0,
            close: false,
            overlay: true,
            id: 'question',
            zindex: 999,
            color: 'white',
            message: 'Soll das Passwort für ' + pw.text() + ' wirklich zurückgesetzt werden?',
            position: 'topCenter',
            buttons: [
                ['<button>Ja</button>', function (instance, toast) {
                    $.ajax({
                        method: 'post',
                        url: '/user/password/reset/' + pw.val(),
                        processData: false,
                        contentType: false,
                        dataType: 'json',
                        statusCode: {
                            200: function (response) {
                                const password = responseHandler(response);
                                const span = $('#resetedPassword');
                                span.parent().show();
                                span.text(password);
                            }
                        }
                    });
                    instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
                }, true],
                ['<button>Nein</button>', function (instance, toast) {
                    instance.hide({transitionOut: 'fadeOut'}, toast, 'button');
                }, false]
            ]
        });
    });


    $('#business_form').on('submit', function (e) {
        console.log('test');
        e.preventDefault();
        // @ts-ignore
        const form = new FormData(document.getElementById('business_form'));
        // @ts-ignore
        form.append('logo', $('#logo')[0].files[0]);
        $.ajax({
            method: 'post',
            url: `/api/put/user/business`,
            data: form,
            processData: false,
            contentType: false,
            dataType: 'json',
            success: function (response) {
                responseHandler(response);
            },
            error: function (response) {
                responseHandler(response.responseJSON);
            }
        });
    });

}