import * as jQuery from "jquery";
import { responseHandler, setValid } from "./utility";

export default () => {

    (function($) {

        $('#new_file_submit').on('click', function () {
            setValid();
            const id = $('#new_file_element').val();
            $.ajax({
                method: 'post',
                url: `/api/post/${id}/files`,
                data: get_form(),
                processData: false,
                contentType: false,
                dataType: 'json',
                success: response => {
                    if (response.status === 200) {
                        window.location.reload();
                    } else {
                        responseHandler(response);
                    }
                },
                error: response => {
                    responseHandler(response.responseJSON);
                }
            });
        });

        $('#edit_file_submit').on('click', function () {
            setValid();
            let id = $(this).data('file');
            $.ajax({
                method: 'post',
                url: `/api/put/files/${id}`,
                data: get_form(),
                processData: false,
                contentType: false,
                dataType: 'json',
                success: response => {
                    if (response.status === 200) {
                        window.location.reload();
                    } else {
                        responseHandler(response);
                    }
                },
                error: response => {
                    responseHandler(response.responseJSON);
                }
            });
        });

        $('#edit_file_cancel').on('click', function () {
            $('.file-editor').fadeOut(function () {
                $('.manager').fadeIn();
            });
        });

        function get_form() {
            let form = new FormData();
            form.append('category', '2');
            form.append('turn', $('#turn').is(":checked") ? 'on' : 'off');
            form.append('element', <string> $('#element').val());
            ['de', 'en', 'fr'].map(lang => {
                form.append(`title_${lang}`, <string> $(`#file_title_${lang}`).val());
                form.append(`content_${lang}`, <string> $(`#file_content_${lang}`).val());
            });
            return form;
        }

    })(jQuery);

}