import * as jQuery from "jquery";
import iziToast from "izitoast";
import { responseHandler } from "./utility";

export default () => {

    (function($) {

        $.fn.extend({
            notify: function (code: number, message: string) {
                $(this).find('.alert').remove();
                if(code >= 200 && code < 300) {
                    $(this).prepend(`
                        <div class="alert alert-success d-flex align-items-center" role="alert">
                            <svg class="bi flex-shrink-0 mr-2" width="16" height="16" role="img" aria-label="Success:"><use xlink:href="#check-circle-fill"/></svg>
                            <div>${message}</div>
                        </div>
                    `);
                } else {
                    $(this).prepend(`
                        <div class="alert alert-danger d-flex align-items-center" role="alert">
                            <svg class="bi flex-shrink-0 mr-2" width="16" height="16" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
                            <div>${message}</div>
                        </div>
                    `);
                }
            }
        });

        $(document).on('click', '.data-trigger', function(e) {
            e.preventDefault();
            const $this = $(this);
            const trigger = () =>  $.ajax({
                method: $this.data('method'),
                url: $this.data('url'),
                success: function (response) {
                    if (response.status !== 204) {
                        $this.closest('.data-container').notify(response.status, response.message);
                    }
                    if (response.status >= 200 && response.status < 300) {
                        if ($this.data('redirect')) {
                            if(`${window.location.pathname}${window.location.hash}` == $this.data('redirect')) {
                                window.location.reload();
                            } else {
                                window.location.href = $this.data('redirect');
                            }
                        }
                    }
                },
                error: function (response) {
                    $this.closest('.data-container').notify(response.responseJSON.status, response.responseJSON.message);
                }
            });
            if($this.data('confirm') == 1) {
                iziToast.question({
                    timeout: 0,
                    close: false,
                    overlay: true,
                    id: 'question',
                    zindex: 999,
                    color: 'orange',
                    message: $this.data('confirm-text'),
                    position: 'topCenter',
                    buttons: [
                        ['<button>Bestätigen</button>', function (instance, toast) {
                            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
                            trigger();
                        }, true],
                        ['<button>Abbrechen</button>', function (instance, toast) {
                            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
                        }, false]
                    ]
                });
            } else {
                trigger();
            }
        });

        $(document).on('submit', '.data-form', function (e) {
            e.preventDefault();
            const $this = $(this);
            $.ajax({
                method: $this.data('method'),
                url: $this.data('url'),
                data: $this.serialize(),
                success: function (response) {
                    if (response.status !== 204) {
                        $this.notify(response.status, response.message);
                    }
                    if (response.status >= 200 && response.status < 300) {
                        if ($this.data('redirect')) {
                            if(`${window.location.pathname}${window.location.hash}` == $this.data('redirect')) {
                                window.location.reload();
                            } else {
                                window.location.href = $this.data('redirect');
                            }
                        }
                    }
                },
                error: function (response) {
                    $this.notify(response.responseJSON.status, response.responseJSON.message);
                }
            });
        });

    })(jQuery);

}