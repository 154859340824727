import * as jQuery from "jquery";
import "./turn";

export default () => {

    (function($) {

        const flip_book = $('#flipbook');

        if(flip_book.length <= 0) {
            return;
        }

        const buttons  = $('.btn-dir i');
        const window_width: number = <number> $(window).width();

        const { w, h } = getSizes();

        if(window_width <= 1024) {

            flip_book.turn({
                width: w,
                height: h,
                duration: 800,
                autoCenter: true,
                display: 'single',
            });

            buttons.removeClass("fa-4x");
            buttons.addClass("fa-2x");

        } else {

            flip_book.turn({
                width: w,
                height: h,
                display: 'double',
                autoCenter: true,
                duration: 800
            });

        }

        // turn to specific page
        let page = $('.flipbook_container').data('page');
        if(page !== 0) {
            console.log('turn to page ' + page);
            flip_book.turn('page', page);
        }


        $(window).on('resize', function() {
            const { w, h } = getSizes();
            flip_book.width(w);
            flip_book.height(h);
        });

        $('.page-left').on('click', function() {
            flip_book.turn('previous');
        });

        $('.page-right').on('click', function(){
            flip_book.turn('next');
        });

        flip_book.bind('last', function() {
            flip_book.addClass('lastpage');
        });

        function getSizes(): { w: number; h: number } {

            // window sizes
            const window_width = Number($(window).width());

            // max sizes (container)
            const container =  $('.flipbook_container');
            const container_width = Number(container.width());
            const container_height = Number(container.height());

            // flipbook
            const flip_book = $('#flipbook');

            // image sizes
            const image_width = flip_book.data('width');
            const image_height = flip_book.data('height');

            if(window_width <= 668) {
                return calculate_mobile(image_width, image_height, container_width, container_height);
            }
            if(window_width > 668 && window_width <= 1024) {
                return calculate_tablet(image_width, image_height, container_width, container_height);
            }
            if(window_width > 1024) {
                return calculate_desktop(image_width, image_height, container_width, container_height);
            }

            return { w: 0, h: 0 };
        }

        function calculate_desktop(img_w: number, img_h: number, cont_w: number, cont_h: number): { w: number, h: number } {
            const ratio = img_w / img_h;
            let flip_book_width: number = 0;
            let flip_book_height : number = 0;
            if(img_w > img_h) {
                flip_book_width = cont_w;
                flip_book_height = cont_h / ratio;
            }
            if(img_w === img_h) {
                flip_book_width = cont_h * 2;
                flip_book_height = cont_h;
            }
            if(img_w < img_h) {
                flip_book_height = cont_h;
                flip_book_width = (flip_book_height * ratio) * 2;
            }
            return { w: flip_book_width, h: flip_book_height };
        }

        function calculate_tablet(img_w: number, img_h: number, cont_w: number, cont_h: number): { w: number, h: number } {
            const ratio = img_w / img_h;
            let flip_book_width: number = 0;
            let flip_book_height : number = 0;
            if(img_w > img_h) {
                flip_book_width = cont_w;
                flip_book_height = cont_h / ratio;
            }
            if(img_w === img_h) {
                flip_book_width = cont_h * 2;
                flip_book_height = cont_h;
            }
            if(img_w < img_h) {
                flip_book_height = cont_h;
                flip_book_width = flip_book_height * ratio;
            }
            return { w: flip_book_width, h: flip_book_height };
        }

        function calculate_mobile(img_w: number, img_h: number, cont_w: number, cont_h: number): { w: number, h: number } {
            const ratio = img_w / img_h;
            let flip_book_width = cont_w;
            let flip_book_height = cont_w / ratio;
            return { w: flip_book_width, h: flip_book_height };
        }

    })(jQuery);

}