import * as jQuery from "jquery";

export default () => {

    (function($) {

        function onReady(callback: () => void): void {
            const intervalID = window.setInterval(() => {
                if (document.getElementsByTagName('body')[0] !== undefined) {
                    window.clearInterval(intervalID);
                    callback();
                }
            }, 250);
        }

        function show(id: string, value: boolean): void {
            const element = $('#' + id);
            value ? element.fadeIn() : element.fadeOut();
        }

        onReady(() => {
            show('page', true);
            show('loading', false);
        });

    })(jQuery);

}