import * as $ from "jquery";
import iziToast, { IziToastSettings } from "izitoast";

export interface Response {
    status: number;
    message: string;
    redirect?: string;
    data: any;
}

export enum NotificationTypes {
    Success,
    Failed,
    Info,
    Warning
}

export enum NotificationSize {
    Small = 'small',
}

export function responseHandler(response: Response, callback: () => void = () => null, delay: number = 10000): any {


    if(response.redirect !== null && response.redirect !== undefined && response.redirect !== '') {
        // @ts-ignore
        if(window.location.href.includes(response.redirect)) {
            window.location.reload();
        } else {
            window.location.href = response.redirect;
        }
        return;
    }

    switch(response.status) {
        case 200: notify(response.message, NotificationTypes.Success, NotificationSize.Small, callback, delay); break;
        case 400: notify(response.message, NotificationTypes.Warning, NotificationSize.Small, callback, delay); break;
        case 500: notify(response.message, NotificationTypes.Failed, NotificationSize.Small, callback, delay); break;
        default: notify(response.message, NotificationTypes.Info, NotificationSize.Small, callback, delay);
    }

    return response.data;
}

export function notify(
    text: string,
    type: NotificationTypes,
    size: NotificationSize = NotificationSize.Small,
    callback: () => void = () => null,
    delay: number = 10000
) {
    const config: IziToastSettings = {
        message: text,
        timeout: delay,
        progressBar: false,
        position: 'topCenter',
        onClosed: function(){
            if(callback && $.isFunction(callback)){
                callback();
            }
        }
    };
    switch (type) {
        default:
        case NotificationTypes.Success: iziToast.success(config); break;
        case NotificationTypes.Failed: iziToast.error(config); break;
        case NotificationTypes.Info: iziToast.info(config); break;
        case NotificationTypes.Warning: iziToast.warning(config); break;
    }
}

export function set_time_mask() {
    $(document).find('.time input').mask('99:99')
}

export function setInvalid(element: string) {
    $(element).addClass('is-invalid');
    $(element).parent().find('.invalid-marker').show();
}

export function setValid() {
    $('input').removeClass('is-invalid');
    $('textarea').removeClass('is-invalid');
    $('.invalid-marker').hide();
}