import * as jQuery from "jquery";
import { NotificationTypes, notify, responseHandler } from "./utility";

export default () => {

    (function($) {

        $('#opening_hour_create').on('submit', function(e) {
            e.preventDefault();
            $.ajax({
                method: 'post',
                url: '/hours',
                data: {
                    name: $('#name').val(),
                    type_de: get_type('de'),
                    title_de: get_input_values('title_de'),
                    description_de: get_input_values('description_de'),
                    type_en: get_type('en'),
                    title_en: get_input_values('title_en'),
                    description_en: get_input_values('description_en'),
                    type_fr: get_type('fr'),
                    title_fr: get_input_values('title_fr'),
                    description_fr: get_input_values('description_fr')
                },
                dataType: 'json',
                success: function(response) {
                    responseHandler(response);
                },
                error: function(response) {
                    responseHandler(response.responseJSON);
                }
            });
        });

        $('#opening_hour_edit').on('submit', function(e) {
            e.preventDefault();
            let group = $(this).data('group');
            $.ajax({
                method: 'post',
                url: '/hours/' + group,
                dataType: 'json',
                data: {
                    name: $('#name').val(),
                    type_de: get_type('de'),
                    title_de: get_input_values('title_de'),
                    description_de: get_input_values('description_de'),
                    type_en: get_type('en'),
                    title_en: get_input_values('title_en'),
                    description_en: get_input_values('description_en'),
                    type_fr: get_type('fr'),
                    title_fr: get_input_values('title_fr'),
                    description_fr: get_input_values('description_fr')
                },
                success: function(response) {
                    responseHandler(response);
                },
                error: function(response) {
                    responseHandler(response.responseJSON);
                }
            });
        });

        $('button[data-action="add_opening_hour"]').on('click', function() {
            $('.copy-rows').each(function(i, row) {
                const language = $(row).data('language');
                $(`.copy-rows[data-language="${language}"]`).append(`
                <div class="row form-group">
                    <div class="col-lg-5">
                        <input
                            type="text"
                            name="title_${language}"
                            class="form-control"
                            placeholder="Zum Beispiel: Montag - Freitag (${language})"
                        />
                    </div>
                    <div class="col-lg-5">
                        <input
                            type="text"
                            name="description_${language}"
                            class="form-control"
                            data-type="1"
                            placeholder="Zum Beispiel: 18.00 - 20.00 Uhr (${language})"
                        />
                    </div>
                    <div class="col-lg-2">
                        <button data-action="remove_line" class="btn btn-sm btn-outline-danger w-100">Löschen</button>
                    </div>
                </div>
            `);
            });
        });

        $('button[data-action="add_text"]').on('click', function() {
            $('.copy-rows').each(function(i, row) {
                const language = $(row).data('language');
                $(`.copy-rows[data-language="${language}"]`).append(`
                <div class="row form-group">
                    <div class="col-lg-10">
                        <input type="hidden" name="title_${language}" />
                        <textarea data-type="2" name="description_${language}" placeholder="Text (${language})" class="form-control"></textarea>
                    </div>
                    <div class="col-lg-2">
                        <button data-action="remove_line" class="btn btn-sm btn-outline-danger w-100">Löschen</button>
                    </div>
                </div>
            `);
            });
        });

        $('button[data-action="add_title"]').on('click', function() {
            $('.copy-rows').each(function(i, row) {
                const language = $(row).data('language');
                $(`.copy-rows[data-language="${language}"]`).append(`
                <div class="row form-group">
                    <div class="col-lg-10">
                        <input type="hidden" name="title_${language}" />
                       <input type="text" name="description_${language}" class="form-control" placeholder="Titel (${language})" data-type="3" />
                    </div>
                    <div class="col-lg-2">
                        <button data-action="remove_line" class="btn btn-sm btn-outline-danger w-100">Löschen</button>
                    </div>
                </div>
            `);
            });
        });

        $(document).on('click', 'button[data-action="remove_line"]', function(e) {
            e.preventDefault();
            const index = $(this).closest('.row').index() + 1;
            $('.copy-rows .row:nth-child(' + index +')').remove();
        });

        $('button[data-action="activate"]').on('click', function(e) {
            e.preventDefault();
            let id = $(this).data('id');
            $.ajax({
                method: 'post',
                url: '/hours/' + id + '/status',
                dataType: 'json',
                success: function (response) {
                    if(response['status'] === 200) {
                        window.location.reload();
                    } else {
                        notify(response['message'], NotificationTypes.Failed);
                    }
                }
            });
        });

        $('button[data-action="delete"]').on('click', function(e) {
            e.preventDefault();
            let id = $(this).data('id');
            $.ajax({
                method: 'post',
                url: '/hours/' + id + '/delete',
                dataType: 'json',
                success: function(response) {
                    if(response['status'] === 200) {
                        window.location.reload();
                    } else {
                        notify(response['message'], NotificationTypes.Failed);
                    }
                }
            })
        });

        function get_type(language: string) {
            return $('input[name="description_' + language + '"], textarea[name="description_' + language + '"]').map(function() {
                return $(this).data('type');
            }).get();
        }

        function get_input_values(index: string) {
            return $('input[name="' + index + '"], textarea[name="' + index + '"]').map(function() {
                return $(this).val();
            }).get();
        }

    })(jQuery);

}