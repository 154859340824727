import * as jQuery from "jquery";
import iziToast from "izitoast";
import { NotificationTypes, notify, responseHandler, set_time_mask, setInvalid, setValid } from "./utility";

export default () => {

    (function($) {

        $('#event_add_date').on('submit', function(e) {
            e.preventDefault();
            const $this = $(this);
            const id = $this.data('event');
            $.ajax({
                method: 'post',
                url: `/api/post/events/${id}/period`,
                data: $(this).serialize(),
                dataType: 'json',
                success: response => {
                    responseHandler(response);
                },
                error: response => {
                    responseHandler(response.responseJSON);
                }
            })
        });

        $('#event_details').on('submit', function(e){
            e.preventDefault();
            let id = $(this).data('event');
            setValid();
            $.ajax({
                method: 'post',
                url: `/api/put/events/${id}`,
                data: get_form_data(),
                processData: false,
                contentType: false,
                dataType: 'json',
                statusCode: {
                    200: function(response){
                        responseHandler(response);
                    },
                    400: function(response){
                        const data = responseHandler(response.responseJSON);
                        $.each(data, function(id, element){
                            setInvalid('#'+element);
                        })
                    },
                    500: function(){
                        notify('Es ist ein Fehler aufgetreten', NotificationTypes.Failed);
                    }
                }
            });
        });

        $('[data-action="delete_date"]').on('click', function(e) {
            e.preventDefault();
            const $this = $(this);
            const event = $this.data('event');
            const period = $this.data('period');
            load_modal(
                `/confirmation/${encodeURI('Möchten Sie das Datum wirklich absagen?')}`,
                '#confirmation_confirm',
                function(cancel) {
                    cancel();
                    $.ajax({
                        method: 'delete',
                        url: `/api/events/period/${period}`,
                        dataType: 'json',
                        success: response => {
                            responseHandler(response);
                            $this.closest('tr').remove();
                        },
                        error: response => {
                            if(response.status === 400) {
                                $.get(`/events/${event}/period/${period}/cancel`).then(response => {
                                    $('body').append(response.data['html']);
                                    $(document).find('.async_modal').modal();
                                    $(document).on('click', '#confirm_cancellation', function (e) {
                                        e.preventDefault();
                                        const $modal = this;
                                        $.ajax({
                                            url: `/api/events/force/period/${period}`,
                                            data: $(document).find('#cancel_period_form').serialize(),
                                            method: 'delete',
                                            dataType: 'json',
                                            success: response => {
                                                responseHandler(response);
                                                $this.closest('tr').remove();
                                                cancel_modal('#confirm_cancellation', '#cancel_cancellation', $modal);
                                            },
                                            error: response => {
                                                responseHandler(response.responseJSON);
                                                cancel_modal('#confirm_cancellation', '#cancel_cancellation', $modal);
                                            }
                                        })
                                    });
                                    $(document).on('click', '#cancel_cancellation', function (e) {
                                        e.preventDefault();
                                        cancel_modal('#confirm_cancellation', '#cancel_cancellation', this);
                                    });
                                });
                            } else {
                                responseHandler(response.responseJSON);
                            }
                        }
                    });
                },
                '#confirmation_cancel',
                cancel => cancel()
            );
        });

        $('.delete').on('click', function(){
            var id = $(this).data('id');
            iziToast.question({
                timeout: 0,
                close: false,
                overlay: true,
                id: 'question',
                zindex: 999,
                color: 'orange',
                message: 'Möchten Sie den Eintrag wirklich löschen?',
                position: 'topCenter',
                buttons: [
                    ['<button>Ja</button>', function (instance, toast) {
                        $.ajax({
                            method: 'post',
                            url: `/api/delete/entry/${id}`,
                            dataType: 'json',
                            success: response => {
                                responseHandler(response);
                                if(response.status === 200) {
                                    $(`#${id}`).hide('slow', function() {
                                        $('#'+id).remove();
                                        $('#cms_button').fadeIn();
                                    });
                                }
                            },
                            error: response => {
                                responseHandler(response.responseJSON);
                            }
                        });
                        instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
                    }, true],
                    ['<button>Nein</button>', function (instance, toast) {
                        instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
                    }, false]
                ]
            });
        });

        $('.delete-file').on('click', function(){
            const id = $(this).data('id');
            iziToast.question({
                timeout: 0,
                close: false,
                overlay: true,
                id: 'question',
                zindex: 999,
                color: 'orange',
                message: 'Möchten Sie die Dateien wirklich löschen?',
                position: 'topCenter',
                buttons: [
                    ['<button>Ja</button>', function (instance, toast) {
                        $.ajax({
                            method: 'delete',
                            url: '/entry/file/'+id,
                            dataType: 'json',
                            statusCode: {
                                200: function(response){
                                    responseHandler(response, function(){
                                        location.reload();
                                    }, 1000);
                                },
                                500: function(response){
                                    responseHandler(response.responseJSON);
                                }
                            }
                        });
                        instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
                    }, true],
                    ['<button>Nein</button>', function (instance, toast) {
                        instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
                    }, false]
                ]
            });
        });

        $('#event_settings').on('submit', function(e) {
            e.preventDefault();
            const $this = $(this);
            const id = $this.data('event');
            $.ajax({
                method: 'post',
                url: `/api/post/events/${id}/settings`,
                data: $this.serialize(),
                dataType: 'json',
                success: function(response) {
                    responseHandler(response);
                },
                error: function(response) {
                    responseHandler(response.responseJSON);
                }
            })
        });

        $('.link-title, .link-url').on('input', function() {
            const link = $(this).parent().parent().find('.link-html');
            const link_url = $(this).parent().parent().find('.link-url');
            const link_title = $(this).parent().parent().find('.link-title');
            let url = <string> link_url.val();
            const urlRegex = new RegExp('^https?:\/\/(.+)$');
            if(url.length > 8) {
                if (!urlRegex.exec(url)) {
                    url = 'http://' + url;
                }
                link_url.val(url);
            }
            link.attr('href', url);
            link.text(link_title.val() !== '' ? <string> link_title.val() : 'Beispiel');
        });

        function get_form_data() {
            let form = new FormData();

            form.append('category','1');
            ['de', 'en', 'fr'].map(language => {
                form.append(`title_${language}`, <string> $(`#title_${language}`).val());
                form.append(`content_${language}`, <string> $(`#content_${language} .ql-editor`).html());
                form.append(`link_title_${language}`, <string> $(`#link_title_${language}`).val());
                form.append(`link_url_${language}`, <string> $(`#link_url_${language}`).val());
            });
            return form;
        }

        $('#registration_form').on('submit', function(e) {
            e.preventDefault();
            const $this = $(this);
            const key = $this.data('key');
            const event = $this.data('event');
            const lang = $this.data('lang');
            const period = $('#period').val();
            const alert = $('#response');
            alert.fadeOut();
            alert.removeClass('alert-success alert-danger');
            $.ajax({
                method: 'post',
                url: `/api/v2/${key}/${event}/${period}/${lang}/registrations`,
                data: $(this).serialize(),
                success: function(response) {
                    if(response['status'] === 200) {
                        alert.addClass('alert-success').text(response['message']).fadeIn();
                        $this.trigger('reset');
                    } else {
                        alert.addClass('alert-danger').text(response['message']).fadeIn();
                    }
                },
                error: function(response) {
                    console.log(response);
                    alert.addClass('alert-danger').text(response['responseJSON']['message']).fadeIn();
                }
            });
        });

        $('[data-action="period"]').on('click', function() {
            const eventId = $(this).data('event');
            const periodId = $(this).data('period');
            function confirm_change(data: any, success: (res: any) => void, error: (res: any) => void, confirm: boolean = false) {
                const url = `/api/put/events/${eventId}/period/${periodId}?confirmation=${confirm ? 'yes' : 'no'}`
                $.ajax({
                    method: 'post',
                    url: url,
                    data: data,
                    dataType: 'json',
                    success: function(response) {
                        success(response);
                    },
                    error: function(response) {
                        error(response.responseJSON);
                    }
                });
            }
            load_form_modal(
                `/modal/${eventId}/${periodId}/change`,
                '#period_change_form',
                function(form: string, close: () => void) {
                    close();
                    const data = $(form).serialize();
                    confirm_change(data, function(response) {
                        responseHandler(response);
                    }, function(response) {
                        if(response.status === 409) {
                            load_modal(
                                `/modal/${eventId}/${periodId}/change/confirm?${data}`,
                                '#period_change_confirm_confirm',
                                function(close) {
                                    confirm_change(data, function(response) {
                                        responseHandler(response);
                                        close();
                                    }, function(response) {
                                        responseHandler(response);
                                    }, true);
                                },
                                '#period_change_confirm_cancel',
                                function(cancel_confirm) {
                                    cancel_confirm();
                                }
                            );
                        } else {
                            responseHandler(response);
                        }
                    });
                },
                '#period_change_cancel',
                function(close: () => void) {
                    close();
                }
            );
        });

        $('[data-action="cancel_registration"]').on('click', function() {
            const registration = $(this).data('registration');
            load_modal(
                `/confirmation/${encodeURI('Möchten Sie die Anmeldung wirklich stornieren?')}`,
                '#confirmation_confirm',
                function(cancel) {
                    $.ajax({
                        method: 'delete',
                        url: `/api/delete/registrations/${registration}`,
                        dataType: 'json',
                        success: function(response) {
                            responseHandler(response);
                            cancel();
                        },
                        error: function(response) {
                            responseHandler(response.responseJSON);
                        }
                    });
                },
                '#confirmation_cancel',
                cancel => cancel()
            );
        });

        $('#download_guest_list').on('click', function() {
            const event = $(this).data('event');
            $.get(`/api/get/${event}/registrations/excel`).then(response => {
                if(response.status === 200) {
                    window.location.href = `/api/get/guests/${response.data.name}/excel`;
                } else {
                    responseHandler(response);
                }
            });
        });

        function load_form_modal(url: string, form: string, submitCallback: any, cancelButton: any, cancelCallback: any) {
            $.get(url).then(response => {
                if(response.status === 200) {
                    $('body').append(response.data['html']);
                    $(document).find('.async_modal').modal();
                    set_time_mask();
                    $(document).on('submit', form, function (e) {
                        e.preventDefault();
                        submitCallback(this, () => cancel_modal(form, cancelButton, this));
                    });
                    $(document).on('click', cancelButton, function (e) {
                        e.preventDefault();
                        cancelCallback(() => cancel_modal(form, cancelButton, this));
                    });
                }
            });
        }

        function load_modal(
            url: string,
            confirmButton: string,
            confirmCallback: (fn: () => void) => void,
            cancelButton: string,
            cancelCallback: (fn: () => void) => void
        ) {
            $.get(url).then(response => {
                if(response.status === 200) {
                    $('body').append(response.data['html']);
                    $(document).find('.async_modal').modal();
                    set_time_mask();
                    $(document).on('click', confirmButton, function (e) {
                        e.preventDefault();
                        confirmCallback(() => cancel_modal(confirmButton, cancelButton, this));
                    });
                    $(document).on('click', cancelButton, function (e) {
                        e.preventDefault();
                        cancelCallback(() => cancel_modal(confirmButton, cancelButton, this));
                    });
                }
            });
        }

        function cancel_modal(confirm: string, cancel: string, self: object) {
            $(document).off('click submit', confirm);
            $(document).off('click submit', cancel);
            $('body').removeClass('modal-open');
            $('.modal-backdrop').remove();
            $(self).closest('.modal').remove();
            $(document).find('.async_modal').modal('hide');
        }

    })(jQuery);

}