import Quill from "quill";
import opening_hours from "./opening_hours";
import files from "./files";
import events from "./events";
import loading from "./loading";
import login from "./login";
import forms from "./forms";

import * as jQuery from "jquery";
import "./masked_input";
import "bootstrap";
import "daterangepicker";
import 'bootstrap/dist/css/bootstrap.min.css';
import "quill/dist/quill.snow.css";
import "daterangepicker/daterangepicker.css";
import "izitoast/dist/css/iziToast.min.css";
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/brands";
import "@fortawesome/fontawesome-free/js/solid";
import settings from "./settings";
import upload from "./upload";
import flipbook from "./flipbook";

opening_hours();
files();
events();
loading();
login();
settings();
forms();
upload();
flipbook();

(function($) {

    $(document).find('.time input').mask('99:99');

    $('.nav-tabs a:not(.no-anchor)[href="' + window.location.hash + '"]').tab('show');

    $('.quill-editor').map(function(i, element) {
        new Quill(`#${element.id}`, {
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike', 'link'],
                    [{'list': 'ordered'}, {'list': 'bullet'}],
                    [{'header': [3, 4, 5, 6, false]}]
                ]
            },
            theme: 'snow'
        });
    });

    $(document).on('DOMSubtreeModified', '.quill-editor', function() {
        const value: string = $(this).find('.ql-editor').html();
        $(this).parent().find('.quill-input').val(value);
    });

    $('.dropzone input').on('change', function () {
        let dropzone: JQuery<HTMLInputElement> = <JQuery<HTMLInputElement>> $(this).parent();
        dropzone.find('div:first-child').hide();
        dropzone.find('.dropzone-file').show();
        // @ts-ignore
        dropzone.find('.dropzone-file span').text(this.value.split('\\')[2]);
    });

    $(".hamburger").on("click", function() {
        $(this).toggleClass("is-active");
        if($(this).hasClass("is-active")) {
            $('#mobile-menu').animate({ left: '0' }, 500);
            $('.admin-container').animate({ left: '250px' }, 500);
        } else {
            $('#mobile-menu').animate({ left: '-250px' }, 500);
            $('.admin-container').animate({ left: '0' }, 500);
        }
    });

    $('.nav-tabs a:not(.no-anchor)').on('click', function (e) {
        e.stopPropagation();
        $(this).tab('show')
    });

    $('.custom-file-input').on('change', function(e) {
        const name = $(this).prop('files')[0].name;
        $(this).parent().find('.custom-file-label').text(name);
    });


    $('.date input').daterangepicker({
        "locale": {
            "format": "DD.MM.YYYY",
            "separator": " - ",
            "applyLabel": "Speichern",
            "cancelLabel": "Abbrechen",
            "fromLabel": "Von",
            "toLabel": "Bis",
            "customRangeLabel": "Custom",
            "weekLabel": "W",
            "daysOfWeek": ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
            "monthNames": [
                "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli",
                "August", "September", "Oktober", "November", "Dezember"
            ],
            "firstDay": 1
        },
        autoApply: true
    });

    $('#time_whole_day').on('click', function() {
        if($(this).is(':checked')) {
            $('#time_from').val('00:00').prop('disabled', true);
            $('#time_until').val('23:59').prop('disabled', true);
        } else {
            $('#time_from').val('').prop('disabled', false);
            $('#time_until').val('').prop('disabled', false);
        }
    });

})(jQuery);

